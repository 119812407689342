<template>
  <b-container>
    <b-row>
      <b-col v-if="ok">
        <h1>Contacts</h1>

        <p>
          The following details have been shared with your email address and will always be as up-to-date as the person keeps them.
        </p>

        <p>
          If you <router-link to="/register">register</router-link> you can also easily share your details and stay Kontaktable.
        </p>

        <!-- TODO: bootstrappify -->
        <div class="contact-list" v-for="item in this.$data.items" :key="item.id">
           <ContactCard v-bind="item"/>
        </div>

      </b-col>
      <b-col v-else-if="error">
        <h1>Oops</h1>
        <p>
          Something has gone a bit sideways...
        </p>
        <p>
          {{ errorMessage }}
        </p>
      </b-col>
      <b-col v-else>
        <h1>Loading Data</h1>
        <p>The monkeys are ferrying things about...</p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// TODO: inprove the look of this page...
import axios from 'axios'
import ContactCard from '@/components/contacts/ContactCard.vue'

export default {
  name: 'EmailShareView',
  components: {
    ContactCard
  },
  data: function() {
    return {
      items: null,
      ok: false,
      error: false,
      errorMessage: "That's interesting..."
    }
  },
  created: function() {
    let token = this.$route.query.token

    console.log("email share view page created....call API and set up data");

    if(token) {
      console.log("token:" + token)
      axios.get('email-shares/' + token)
      .then(response => {
        console.log("api status: " + response.status + " " + response.statusText)
        console.log('response.data;')
        console.log(response.data)

        if(response.status == 200) {
          this.$data.ok = true
          this.$data.items = response.data
        } else {
          this.$data.error = true
          this.$data.errorMessage = "Can't get data for token: " + response.statusText
        }
      }, err => {
          this.$data.error = true
          this.$data.errorMessage = "Something went badly wrong - " + err
      })
    } else {
      this.$data.error = true
      this.$data.errorMessage = "No token supplied, did you copy the URL exactly as it was delivered to you?"
    }
  }
}
</script>
